<template>
  <h1 style="text-align: center">Coming soon to Studiospace</h1>
  <!-- <BoardLayout mainLabel="Books" :tabs="tabs"></BoardLayout> -->
</template>
//
<script>
// import { defineComponent } from 'vue'
// import BoardLayout from '@/components/templates/BoardLayout.vue'
// export default defineComponent({
//   components: {
//     BoardLayout
//   },
//   setup() {
//     const tabs = [
//       { routerName: 'BooksDashboard', routerText: 'Dashboard' },
//       { routerName: 'Invoicing', routerText: 'Invoicing' },
//       { routerName: 'Expenses', routerText: 'Expenses' },
//       { routerName: 'Finance', routerText: 'Finance' }
//     ]
//     return { tabs }
//   }
// })
//
</script>
